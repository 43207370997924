import mixpanel, { Dict } from "mixpanel-browser";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
// const env = process.env.REACT_APP_API_ENV;
// const isProduction = env === 'production';

export const Mixpanel = {
  init: () => {
    if (MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN);
    }
  },
  identify: (id?: string) => {
    mixpanel.identify(id);
  },
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
};
