import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TopNavigation from "./TopNavigation";
import StudioTab from "./tabs/StudioTab";
import FriendsTab from "./tabs/FriendsTab";

import {
  getBooleanParameterFromQuery,
  getQueryParameters,
} from "../utils/misc";
import { Mixpanel } from "../utils/mixpanel";
import { NavigationTabTypes } from "../constants/navigation";
import { TAB_STORAGE_KEY } from "../constants/storage";
import { getValueFromStorage, setValueToStorage } from "../utils/storage";
import { MixpanelEvents } from "../utils/mixpanelEvents";

import "./main.scss";

const { Studio, Friends } = NavigationTabTypes;

const NavigationItems = [
  {
    name: "Studio Leaderboard",
    id: Studio,
  },
  {
    name: "FG Friends Weekly Leaderboard",
    id: Friends,
  },
];

const Main: React.FC = () => {
  const [activeTabId, setActiveTab] = useState<string>("studio-rankings");

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const variables = getQueryParameters();
  const isInIframe = getBooleanParameterFromQuery("iframe");
  const isSA = getBooleanParameterFromQuery("sa");

  useEffect(() => {
    if (variables.challengeId || variables.sgId) {
      Mixpanel.identify();
    }

    const value =
      getValueFromStorage(TAB_STORAGE_KEY) || NavigationTabTypes.Studio;

    setActiveTab(value);
  }, []);

  const isStudioTab = activeTabId === NavigationTabTypes.Studio;

  const onSetActiveTab = (tabId: string) => {
    setActiveTab(tabId);
    setValueToStorage(TAB_STORAGE_KEY, tabId);
  };

  const onClick = () => {
    const Events = MixpanelEvents[activeTabId].LetUsKnow;
    Mixpanel.track(Events.name, { type: Events.type });
  };

  const topNavigationItems = isSA
    ? [
        {
          name: "Studio Leaderboard",
          id: Studio,
        },
      ]
    : NavigationItems;

  return (
    <div className="main">
      {!isInIframe && (
        <header className="header-container">
          <TopNavigation
            tabs={topNavigationItems}
            activeTabId={activeTabId}
            onSetActiveTab={onSetActiveTab}
          />
        </header>
      )}
      {isStudioTab ? (
        <StudioTab
          variables={variables}
          activeTabId={activeTabId}
          isMobile={isMobile}
          isInIframe={isInIframe}
          isSA={isSA}
        />
      ) : (
        <FriendsTab
          variables={variables}
          activeTabId={activeTabId}
          isMobile={isMobile}
        />
      )}
      {!isInIframe && (
        <footer className="footer-container">
          <div className="footer-container-content">
            <span className="footer-container-text">
              Have thoughts or feedback?
            </span>{" "}
            <a
              className="footer-container-link"
              href="mailto:challenges@fitgrid.com"
              onClick={onClick}
            >
              Let us know here
            </a>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Main;
