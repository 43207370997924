import React from "react";
import { CircularProgress } from "@mui/material";

const Loader: React.FC = () => {
  return (
    <div
      className="app-loader"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "75vh",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export { Loader };
