import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { getBooleanParameterFromQuery } from "../utils/misc";

const env = process.env.REACT_APP_API_ENV;

const API_URI = {
  production: "https://client-api.fitgrid.com/clients/queries",
  development: "https://client-api.dev.fitgrid.com/clients/queries",
  qa: "https://client-api.qa.fitgrid.com/clients/queries",
};

const SA_API_URL = {
  production: "https://api.fitgrid.com/queries",
  development: "https://api.qa.fitgrid.com/queries",
  qa: "https://api.qa.fitgrid.com/queries",
};

const isSA = getBooleanParameterFromQuery("sa");
const isIframe = getBooleanParameterFromQuery("iframe");

const isSAApi = isSA || isIframe;

export const link = createHttpLink({
  uri: isSAApi ? SA_API_URL[env] : API_URI[env],
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
