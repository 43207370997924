import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import { EnhancedDialogTitle } from "./EnhancedDialogTitle";
import { TermsAndConditionsLink } from "./TermsAndConditionsLink";

import "./dialog.scss";
import { NavigationTabTypes } from "../../constants/navigation";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

function FriendsDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <EnhancedDialogTitle title="Friends Leaderboard" onClose={handleClose} />
      <DialogContent dividers>
        <Typography variant="h6">
          How do I remove friends from this table?{" "}
          <Typography variant="body1">
            <ul>
              <li>
                <Typography variant="body1">
                  Go to your profile picture at the top left of the app
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  At the very bottom you will see “Settings”
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Choose “Class Reservation Notifications”
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Tap on “Don’t show upcoming classes of these friends”
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Select the friends you don’t want to see on this list
                </Typography>
              </li>
              <br />
              <Typography variant="subtitle2">
                Note: Turning this off will also make sure you don’t get
                notified when these friends sign up for a class.
              </Typography>
            </ul>
          </Typography>
        </Typography>

        <Typography variant="h6">
          How do I make sure I am not visible on their table to certain friends?{" "}
          <ul>
            <li>
              <Typography variant="body1">
                Go to your profile picture at the top left of the app
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                At the very bottom you will see “Settings”
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Go to “Notifications” - Choose “Class Reservation Notifications”
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Turn on “Let friends see my upcoming classes”
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Under “Exceptions” select the friends you don’t want to see how
                you’re doing
              </Typography>
            </li>
            <br />
            <Typography variant="subtitle2">
              {" "}
              Note: Choosing “exceptions” makes sure those friends do not get
              notified when you sign up for a class also.
            </Typography>
          </ul>
        </Typography>
        <br />
        <TermsAndConditionsLink
          id={NavigationTabTypes.Friends}
          href={"https://terms.fitgrid.com/terms-challenges.html"}
        />
      </DialogContent>
    </Dialog>
  );
}

export default FriendsDialog;
