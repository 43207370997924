import React from "react";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";

import "./outside-cell.scss";

type Props = {
  style: any;
  renderItems(row: any): any;
  currentUser: any;
};

export function OutsideTableCell({ style, renderItems, currentUser }: Props) {
  return (
    <React.Fragment>
      <TableRow className="delimeter-row" key="empty-row">
        <TableCell>...</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow key="last-row-key" sx={style}>
        {renderItems(currentUser)}
      </TableRow>
    </React.Fragment>
  );
}
