import React from "react";
import NavigationTabs from "./tabs/NavigationTabs";
import { NavigationTabsProps } from "../types/navigation";

const TopNavigation: React.FC<NavigationTabsProps> = ({
  tabs,
  activeTabId,
  onSetActiveTab,
}) => {
  return (
    <NavigationTabs
      tabs={tabs}
      activeTabId={activeTabId}
      onSetActiveTab={onSetActiveTab}
    />
  );
};

export default TopNavigation;
