import React from "react";
import TableCell from "@mui/material/TableCell";
import { MixpanelEvents } from "../../../utils/mixpanelEvents";
import { Mixpanel } from "../../../utils/mixpanel";

import "./client-name.scss";

type ClientNameCellProps = {
  type: string;
  row: any;
  isMobile?: boolean;
  className?: string;
  clientName: string;
};

export function ClientNameCell({
  type,
  row,
  isMobile = false,
  className,
  clientName,
}: ClientNameCellProps) {
  if (!type) {
    return null;
  }

  const onClick = () => {
    const Events = MixpanelEvents[type].UserName;
    Mixpanel.track(Events.name, { type: Events.type });
  };

  if (isMobile) {
    return (
      <div className="client-name-cell" onClick={onClick}>
        {clientName}
      </div>
    );
  }

  return (
    <TableCell className={className} align="left" onClick={onClick}>
      {clientName}
    </TableCell>
  );
}
