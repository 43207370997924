import { loader } from "graphql.macro";

export const GetStudioLeaderboardBreakdownQuery = loader(
  "./queries/GetStudioLeaderboardBreakdownQuery.graphql"
);

export const GetFriendsLeaderboardQuery = loader(
  "./queries/GetFriendsLeaderboardQuery.graphql"
);

export const GetFitgridChallengePublicQuery = loader(
    "./queries/GetFitgridChallengePublicQuery.graphql"
);
