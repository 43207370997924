import React from "react";
import TableCell from "@mui/material/TableCell";
import cx from 'classnames';

import './completed-weekly-cell.scss';

type Props = {
  row: any;
  cellClass: any;
  isCompleted?: boolean;
};

export function CompletedWeeklyCell({ row, cellClass, isCompleted }: Props) {
  const completedClass = cx({
    'weekly-cell': true,
    'bold-text': isCompleted,
    'grayed-out-text': !isCompleted,
  })

  const completedWorkoutText = (
    <span className={completedClass}>{row.completedWorkoutsCount}</span>
  );
  const personalWorkoutText = (
    <span className="weekly-cell normal-text">{row.personalWorkoutsGoal}</span>
  );
  return (
    <TableCell width={460} className={cellClass} size="medium" align="center">
      {completedWorkoutText} / {personalWorkoutText}
    </TableCell>
  );
}
