import React  from "react";
import { ReactComponent as SkeletonIcon } from "../../icons/skeleton_icon.svg";
import { ReactComponent as SkeletonMobileIcon } from "../../icons/mobile_skeleton_icon.svg";
import { useMediaQuery } from "react-responsive";

import "./no-participants.scss";

export function NoParticipantsPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div className="no-participants-container">
      <div className="no-participants-container--icon">
        {isMobile ? <SkeletonMobileIcon /> : <SkeletonIcon />}
      </div>

      <div className="no-participants-container--text">
        No clients have reserved a challenge class through Class App yet
      </div>
    </div>
  );
}
