import React from "react";
import { TableCell } from "@mui/material";
import cx from "classnames";

import "./goals.scss";

type GoalsProps = {
  row: any;
  challengeGoalCount: number;
  isCompleted?: boolean;
};

export function Goals({ row, challengeGoalCount, isCompleted }: GoalsProps) {
  const cellClass = cx({
    "goals-cell": true,
    highlighted: isCompleted,
  });

  const text = (
    <div>
      <span className={`${isCompleted ? "bold-text" : "regular-text"}`}>
        {row.completedWorkoutsCount}
      </span>
      {" / "}
      <span>{challengeGoalCount}</span>
    </div>
  );

  return (
    <TableCell align="center" size={"small"} className={cellClass}>
      <div className="goals-inner">{text}</div>
    </TableCell>
  );
}
