import React from "react";
import { ReactComponent as StrongSvg } from "../../../icons/strong.svg";

import "./friends-tab-content.scss";

type Props = {
  onClick(): void;
};

export function FriendsTopContent({ onClick }: Props) {
  const iconElement = (
    <div className="icon">
      <StrongSvg />
    </div>
  );

  return (
    <div className="friends-top-content">
      <ul className="section">
        <li className="list-item">
          {iconElement}
          <div className="list-text">
            They say the best way to meet your goals is with accountability! Use
            this board as personal inspiration and to motivate your friends and
            family to meet their goals.
          </div>
        </li>
        <li className="list-item">
          {iconElement}
          <div className="list-text">
            These are the workouts and activities of your FitGrid friends,
            including both studio classes and manual entries in the FitGrid App
            Workout Tracker.
          </div>
        </li>
        <li className="list-item">
          {iconElement}
          <div className="list-text">
            Add more friends to this space by inviting them to FitGrid and
            connecting within the app.
            <br />
            <span className="more-details-link" onClick={onClick}>
              More details
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}
