import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type Options = {
  studioName: string;
  id: number;
};
type Props = {
  options: Options[];
  onChange(event: SelectChangeEvent): void;
  fitnessNetworkIndex: string;
  fullWidth?: boolean;
};

const BasicSelect: React.FC<Props> = ({
  options,
  fitnessNetworkIndex,
  onChange,
  fullWidth
}) => {
  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Studio</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={fitnessNetworkIndex || '0'}
          label="Studio"
          onChange={onChange}
          fullWidth={fullWidth}
        >
          {options.map(({ studioName, id }: Options) => {
            return (
              <MenuItem key={id} value={id}>
                {studioName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
