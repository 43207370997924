import React, { useEffect } from "react";
import { ReactComponent as NoResultsSvg } from "../../icons/no-results.svg";
import { MixpanelEvents } from "../../utils/mixpanelEvents";
import { Mixpanel } from "../../utils/mixpanel";

import "./no-results.scss";

type Props = {
  id: string;
}

export function NoResultsPage({ id }: Props) {
  useEffect(() => {
    const Events = MixpanelEvents[id].Page404;
    Mixpanel.track(Events.name, { type: Events.type });
  }, [])
  return (
    <div className="no-results-container">
      <div className="no-results-container--icon">
        <NoResultsSvg />
      </div>

      <div className="no-results-container--title">
        Oops, it looks like your link has expired
      </div>
      <div className="no-results-container--text">
        Tap the Leaderboard button in your FitGrid app to refresh this page.
      </div>
    </div>
  );
}
