import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import { EnhancedDialogTitle } from "./EnhancedDialogTitle";
import { TermsAndConditionsLink } from "./TermsAndConditionsLink";
import { NavigationTabTypes } from "../../constants/navigation";
import {formatToDateString} from "../../utils/misc";

import "./dialog.scss";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  isSA?: boolean;
  selectedNetwork: any;
}

function StudioDialog(props: SimpleDialogProps) {
  const { onClose, open, isSA, selectedNetwork } = props;

  const endDatePath = isSA ? 'endTime' : 'challengeEndTime';
  const endDate = formatToDateString(selectedNetwork[endDatePath]);

  const handleClose = () => {
    onClose();
  };

  const typographyStyle = { marginTop: 10, marginBottom: 15 };

  return (
    <Dialog maxWidth="md" onClose={handleClose} open={open}>
      <EnhancedDialogTitle title="Studio Leaderboard" onClose={handleClose} />`
      <DialogContent dividers>
        <div className="disclaimer">
          <i>
            *There are no additional booking fees to buy and reserve a class on
            FitGrid! To be eligible to win, classes must be reserved through the
            FitGrid Class App. You can also enter to win by completing{" "}
            <a
              className="disclaimer-anchor-link"
              target="_blank"
              href={"https://form.typeform.com/to/PKbMHic3"}
            >
              this survey
            </a>
            . <strong>*NO PURCHASE NECESSARY.</strong> Entrants must be 18+,
            U.S. & D.C. Ends <strong>11:59</strong> PM Eastern Time on{" "}
            <strong>{endDate}.</strong>
          </i>
        </div>
        <br />

        <Typography variant="h6">
          What is this table?
          <Typography variant="body1" style={typographyStyle}>
            These are other clients at your studio using the FitGrid App.
            Encourage friends from your studio to join, and make it a real
            community interaction!
          </Typography>
        </Typography>

        <Typography variant="h6">
          How do I play
          <Typography variant="body1" style={typographyStyle}>
            Just reserve your classes as you always do, but through the FitGrid
            Class app. Attend <strong>4</strong> classes and you are automatically entered into a
            drawing to win.
          </Typography>
        </Typography>
        <br />
        <TermsAndConditionsLink
          id={NavigationTabTypes.Studio}
          href={"https://terms.fitgrid.com/terms-challenges.html"}
        />
      </DialogContent>
    </Dialog>
  );
}

export default StudioDialog;
