import React from "react";
import { Mixpanel } from "../../utils/mixpanel";
import { MixpanelEvents } from "../../utils/mixpanelEvents";

export function TermsAndConditionsLink({ href, id }: { href: string, id: string }) {
  const onClick = () => {
    const Events = MixpanelEvents[id].TermsLink;
    Mixpanel.track(Events.name, { type: Events.type });
  }

  return (
    <a
      target="_blank"
      style={{
        fontSize: 14,
        fontWeight: "600",
        textDecoration: "underline",
        color: "#2EAEFF",
      }}
      href={href}
      onClick={onClick}
    >
        Challenge Terms & Conditions
    </a>
  );
}
