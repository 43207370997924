import React from "react";
import cx from "classnames";
import { Avatar as MUIAvatar, Badge } from "@mui/material";

import { ReactComponent as CheckmarkGoldSvg } from "../../../icons/checkmark_gold.svg";
import { ReactComponent as CheckmarkGreySvg } from "../../../icons/checkmark_grey.svg";

import { NavigationTabTypes } from "../../../constants/navigation";
import { Mixpanel } from "../../../utils/mixpanel";
import { MixpanelEvents } from "../../../utils/mixpanelEvents";
import { getBooleanParameterFromQuery } from "../../../utils/misc";

import "./avatar.scss";

type AvatarCellProps = {
  type: string;
  row: any;
  isCompleted?: boolean;
  isCurrentUser?: boolean;
};

export function Avatar({
  type,
  row,
  isCompleted,
  isCurrentUser,
}: AvatarCellProps) {
  if (!type) {
    return null;
  }

  const isInIframe = getBooleanParameterFromQuery('iframe');
  const isSA = getBooleanParameterFromQuery('sa');

  const isStudioAdmin = isInIframe || isSA

  const isStudio = type === NavigationTabTypes.Studio;
  const isFriends = type === NavigationTabTypes.Friends;

  const onClick = () => {
    const Events = MixpanelEvents[type].Avatar;
    Mixpanel.track(Events.name, { type: Events.type });
  };

  const connected = isCompleted && row.isOpenToConnecting;
  const highlighted = isFriends || isStudioAdmin ? isCompleted : connected;
  const currentUserHighlighted = isFriends ? row.isCurrent : isCurrentUser;

  const borderClass = cx({
    "connected-highlight": highlighted,
    "current-user-highlight": currentUserHighlighted,
    "no-highlight": isFriends ? !row.isCurrent && !isCompleted : !isCompleted && !isCurrentUser,
  });

  const iconContent = (
    <div className="icon-container">
      {highlighted ? <CheckmarkGoldSvg /> : <CheckmarkGreySvg />}
    </div>
  );

  const renderStudioContent = () => {
    return row.avatar && row.avatar.mediumUrl && row.isOpenToConnecting ? (
      <MUIAvatar src={row.avatar.mediumUrl} />
    ) : (
      <MUIAvatar>
        {row?.firstName?.charAt(0)} {row?.lastName}
      </MUIAvatar>
    );
  };

  const renderFriendsContent = () => {
    return row.avatar && row.avatar.mediumUrl ? (
      <MUIAvatar src={row.avatar.mediumUrl} />
    ) : (
      <MUIAvatar>
        {row.firstName.charAt(0)} {row.lastName}
      </MUIAvatar>
    );
  };

  return (
    <div className="avatar-container" onClick={onClick}>
      <div className={borderClass}>
        <Badge overlap="circular" badgeContent={iconContent}>
          {isStudio ? renderStudioContent() : renderFriendsContent()}
        </Badge>
      </div>
    </div>
  );
}
