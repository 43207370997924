import React from "react";
import { ApolloProvider } from "@apollo/client";

import Main from "./component/Main";

import { client } from "./apollo/config";
import { Mixpanel } from "./utils/mixpanel";

import "./App.scss";

Mixpanel.init();

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="app-container">
        <Main />
      </div>
    </ApolloProvider>
  );
}

export default App;
