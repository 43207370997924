import React, { useCallback } from "react";
import cx from "classnames";

import { MixpanelEvents } from "../../utils/mixpanelEvents";
import { Mixpanel } from "../../utils/mixpanel";

import "./navigation-tab.scss";

type NavigationTabProps = {
  onSetActiveTab(id: string): void;
  navigationTabId: string;
  navigationTabName: string;
  isNavigationTabActive: boolean;
};

const NavigationTab = ({
  onSetActiveTab,
  navigationTabId,
  navigationTabName,
  isNavigationTabActive,
}: NavigationTabProps) => {
  const handleNavigationTabClick = useCallback(() => {
    onSetActiveTab(navigationTabId);

    const Event = MixpanelEvents[navigationTabId].TabChange;
    Mixpanel.track(Event.name, { type: Event.type });
  }, [navigationTabId, onSetActiveTab]);

  const navigationTabClass = cx({
    "navigation-tab": true,
    "navigation-tab--active": isNavigationTabActive,
  });

  return (
    <div className={navigationTabClass} onClick={handleNavigationTabClick}>
      <div className="navigation-title">{navigationTabName}</div>
    </div>
  );
};

export default NavigationTab;
