import React from "react";
import { ReactComponent as LockSvg } from "../../icons/lock.svg";

import './locked-from-challenge.scss';

export function LockedFromChallenge({ text }: { text: string }) {
  return (
    <div className="locked-from-challenge-container">
      <div className="icon-container">
        <LockSvg />
      </div>
      <span className="locked-text">
        {text}
      </span>
    </div>
  );
}
