import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { SelectChangeEvent } from "@mui/material/Select";
import cx from "classnames";

import BasicTable from "../table/Table";
import BasicSelect from "../Select";
import StudioDialog from "../dialogs/StudioDialog";
import { Loader } from "../Loader";

import {
  GetStudioLeaderboardBreakdownQuery,
  GetFitgridChallengePublicQuery,
} from "../../apollo/queries";
import { MixpanelEvents } from "../../utils/mixpanelEvents";
import { Mixpanel } from "../../utils/mixpanel";
import MobileTable from "../table/MobileTable";
import { StudioTabTopContent } from "./components/StudioTabTopContent";
import { QueryVariables } from "../../types/api";
import { LockedFromChallenge } from "../common/LockedFromChallenge";
import { NoResultsPage } from "../common/NoResultsPage";
import { getValueFromStorage, setValueToStorage } from "../../utils/storage";
import { FITNESS_NETWORK_STORAGE_KEY } from "../../constants/storage";
import { NavigationTabTypes } from "../../constants/navigation";
import { getParticipantsNodes } from "../../utils/misc";
import { NoParticipantsPage } from "../common/NoParticipants";

import "./studio-tab.scss";

type Props = {
  activeTabId: string;
  clientAccountId?: string | null;
  isMobile?: boolean;
  variables: QueryVariables;
  isInIframe?: boolean;
  isSA?: boolean;
};

const StudioTab: React.FC<Props> = ({
  activeTabId,
  isMobile,
  variables,
  isInIframe,
  isSA,
}) => {
  const [fitnessNetworkIndex, setFitnessNetworkIndex] = useState("");
  const [open, setOpen] = useState(false);

  const isStudioAdmin = isInIframe || isSA;

  const query = isStudioAdmin
    ? GetFitgridChallengePublicQuery
    : GetStudioLeaderboardBreakdownQuery;

  const { loading, data } = useQuery<any, any>(query, {
    skip: !variables.sgId || !variables.challengeId,
    variables: {
      challengeId: variables.challengeId,
      sgId: variables.sgId,
    },
  });

  useEffect(() => {
    if (!isStudioAdmin) {
      const fnIndex = getValueFromStorage(FITNESS_NETWORK_STORAGE_KEY) || "";
      setFitnessNetworkIndex(fnIndex);
    }
  }, []);

  useEffect(() => {
    if (!isStudioAdmin) {
      const fitnessNetworkName =
        data?.studioBreakdownLeaderboard[Number(fitnessNetworkIndex)]
          ?.studioName;

      if (fitnessNetworkName) {
        const { OnTab, ActiveFN } = MixpanelEvents[activeTabId] as any;

        Mixpanel.track(OnTab.name, { type: OnTab.type, fitnessNetworkName });
        Mixpanel.track(ActiveFN.name, { type: ActiveFN.type });
      }
    }
  }, [data, isStudioAdmin]);

  const handleClickOpen = () => {
    setOpen(true);

    const Events = MixpanelEvents[activeTabId].MoreDetails;
    Mixpanel.track(Events.name, { type: Events.type });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <Loader />;
  }

  const items = isStudioAdmin
    ? data?.fitgridChallengePublic
    : data?.studioBreakdownLeaderboard;

  if (!data || items.length === 0) {
    return <NoResultsPage id={NavigationTabTypes.Studio} />;
  }

  const breakdown = items;

  const selectOptions =
    (!isStudioAdmin &&
      breakdown?.map((item: any, index: number) => {
        return { studioName: item.studioName, id: index };
      })) ||
    [];

  const onFitnessNetworkChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string;
    setFitnessNetworkIndex(id);
    setValueToStorage(FITNESS_NETWORK_STORAGE_KEY, id);

    const Events = MixpanelEvents[activeTabId].ActiveFN as any;
    Mixpanel.track(Events.name, {
      type: Events.type,
      studioName: selectOptions[id].studioName,
    });
  };

  const getSelectedNetwork = () => {
    const fn = breakdown?.[Number(fitnessNetworkIndex)];
    const isSelectedNetworkExist = !!fn;

    if (!isSelectedNetworkExist) {
      setFitnessNetworkIndex("0");
    }
    return fn;
  };

  const selectedNetwork = isStudioAdmin ? items : getSelectedNetwork();

  const TableComponent = isMobile ? MobileTable : BasicTable;

  if (!selectedNetwork) {
    return <NoResultsPage id={NavigationTabTypes.Studio} />;
  }

  const { participants, isUserOutsideOfTopTwenty } = getParticipantsNodes(
    selectedNetwork,
    true,
    selectedNetwork.currentParticipant
  );

  const studioTabClassName = cx({
    "studio-tab-container": true,
    "iframe-container": isInIframe,
  });

  const studioTabContentClassName = cx({
    content: true,
    "iframe-content": isInIframe,
  });

  if (participants.length === 0 && isStudioAdmin) {
    return <NoParticipantsPage />;
  }

  return (
    <div className={studioTabClassName}>
      <section className={studioTabContentClassName}>
        {!isInIframe && (
          <StudioTabTopContent
            selectedNetwork={isStudioAdmin ? items : selectedNetwork}
            isSA={isSA}
            onClick={handleClickOpen}
          />
        )}
        {!selectedNetwork.currentParticipant && !isStudioAdmin && (
          <LockedFromChallenge text="Reserve your first class in the FitGrid app, then come back here once you’ve attended to see how you’re doing!" />
        )}
        {!isStudioAdmin && (
          <div className="select-container">
            <BasicSelect
              onChange={onFitnessNetworkChange}
              fitnessNetworkIndex={fitnessNetworkIndex}
              options={selectOptions}
              fullWidth={isMobile}
            />
          </div>
        )}
        <TableComponent
          activeTabId={activeTabId}
          data={participants}
          currentParticipant={selectedNetwork.currentParticipant}
          isUserOutsideOfTopTwenty={isUserOutsideOfTopTwenty}
          challengeGoalCount={
            isStudioAdmin ? items.goalCount : selectedNetwork.challengeGoalCount
          }
        />
      </section>
      {!isInIframe && (
        <StudioDialog
          open={open}
          onClose={handleClose}
          isSA={isSA}
          selectedNetwork={selectedNetwork}
        />
      )}
    </div>
  );
};

export default StudioTab;
