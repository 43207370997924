import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GetFriendsLeaderboardQuery } from "../../apollo/queries";

import FriendsDialog from "../dialogs/FriendsDialog";
import BasicTable from "../table/Table";
import { Loader } from "../Loader";
import { MixpanelEvents } from "../../utils/mixpanelEvents";
import { Mixpanel } from "../../utils/mixpanel";
import { FriendsTopContent } from "./components/FriendsTopContent";
import { QueryVariables } from "../../types/api";
import { NoResultsPage } from "../common/NoResultsPage";
import { NavigationTabTypes } from "../../constants/navigation";
import { getParticipantsNodes } from "../../utils/misc";

import MobileTable from "../table/MobileTable";

import "./frinends-tab.scss";

type Props = {
  activeTabId: string;
  clientAccountId?: string | null;
  isMobile?: boolean;
  variables: QueryVariables;
};

const FriendsTab: React.FC<Props> = ({ activeTabId, isMobile, variables }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const Events = MixpanelEvents[activeTabId].OnTab;
    Mixpanel.track(Events.name, { type: Events.type });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);

    const Events = MixpanelEvents[activeTabId].MoreDetails;
    Mixpanel.track(Events.name, { type: Events.type });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { loading, data } = useQuery<any, any>(GetFriendsLeaderboardQuery, {
    skip: !variables.sgId,
    variables: {
      sgId: variables.sgId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data || data.friendsLeaderboard.length === 0) {
    return <NoResultsPage id={NavigationTabTypes.Friends} />;
  }

  const TableComponent = isMobile ? MobileTable : BasicTable;

  const { participants } = getParticipantsNodes(
    data.friendsLeaderboard,
    false,
    data.currentParticipant
  );

  return (
    <div className="friends-tab-container">
      <FriendsTopContent onClick={handleClickOpen} />
      <section className="container-inner">
        <TableComponent
          activeTabId={activeTabId}
          data={participants}
          challengeGoalCount={data.challengeGoalCount}
        />
      </section>
      <FriendsDialog open={open} onClose={handleClose} />
    </div>
  );
};

export default FriendsTab;
