import React from "react";
import cx from "classnames";
import {
  Paper,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
} from "@mui/material";

import { NavigationTabTypes } from "../../constants/navigation";
import { Participants } from "./components/columns/Participants";
import { Goals } from "./components/columns/Goals";
import { OutsideTableCell } from "./components/OutsideTableCell";
import { CompletedWeeklyCell } from "./components/CompletedWeeklyCell";
import { LockedFromChallenge } from "../common/LockedFromChallenge";
import { LOCKED_FROM_CHALLENGE_TEXT } from "../../constants/table";
import { getBooleanParameterFromQuery } from "../../utils/misc";

import "./table.scss";

interface Props {
  data: any;
  activeTabId: string;
  isUserOutsideOfTopTwenty?: boolean;
  currentParticipant?: any;
  challengeGoalCount: number;
}

const BasicTable: React.FC<Props> = ({
  data,
  activeTabId,
  isUserOutsideOfTopTwenty,
  currentParticipant,
  challengeGoalCount,
}) => {
  const isIframe = getBooleanParameterFromQuery("iframe");
  const isStudioTab = activeTabId === NavigationTabTypes.Studio;

  const renderStudioItems = (row: any) => {
    const isCompleted = row.completedWorkoutsCount >= challengeGoalCount;

    const cellClass = cx({
      "is-completed-cell": true,
      highlighted: isCompleted,
    });

    return (
      <React.Fragment>
        <Participants
          type={NavigationTabTypes.Studio}
          row={row}
          isCompleted={isCompleted}
          currentUser={currentParticipant}
        />
        <Goals
          row={row}
          challengeGoalCount={challengeGoalCount}
          isCompleted={isCompleted}
        />
        <TableCell className={cellClass} align="center">
          {row.isOpenToConnecting ? (
            <span>Yes</span>
          ) : (
            <span className="grayed-out-text">No</span>
          )}
        </TableCell>
      </React.Fragment>
    );
  };

  const renderFriendsItems = (row: any, index: number) => {
    const isCompleted = row.completedWorkoutsCount >= row.personalWorkoutsGoal;

    const cellClass = cx({
      "is-completed-cell": true,
      highlighted: isCompleted,
    });

    return (
      <React.Fragment>
        <Participants
          type={NavigationTabTypes.Friends}
          row={row}
          index={index}
          isCompleted={isCompleted}
          width={350}
        />
        <CompletedWeeklyCell
          isCompleted={isCompleted}
          cellClass={cellClass}
          row={row}
        />
      </React.Fragment>
    );
  };

  const renderStudioHead = () => {
    return (
      <React.Fragment>
        <TableCell className="table-cell-header" align="left">
          Participants
        </TableCell>
        <TableCell className="table-cell-header" align="center">
          Completed / Goal
        </TableCell>
        <TableCell className="table-cell-header" align="center">
          Open to connecting
        </TableCell>
      </React.Fragment>
    );
  };

  const renderFriendsHead = () => {
    return (
      <React.Fragment>
        <TableCell
          width={350}
          className="table-cell-header"
          size="small"
          align="left"
        >
          Friends
        </TableCell>
        <TableCell width={460} className="table-cell-header" align="center">
          Completed / Weekly Goal
        </TableCell>
      </React.Fragment>
    );
  };

  const lastTableRowStyle = {
    "&:last-child td, &:last-child th": { border: 0 },
  };

  if (data.length === 0 && isStudioTab) {
    return null;
  } else if (data.length === 0 && !isStudioTab) {
    return <LockedFromChallenge text={LOCKED_FROM_CHALLENGE_TEXT} />;
  }

  const renderTable = () => {
    const tableProps = isIframe ? { width: "auto" } : { sx: { maxWidth: 810 } };

    return (
      <Table {...tableProps} aria-label="simple table">
        <TableHead>
          <TableRow>
            {isStudioTab ? renderStudioHead() : renderFriendsHead()}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any, index: number) => {
            return (
              <TableRow
                key={index}
                sx={isUserOutsideOfTopTwenty ? {} : lastTableRowStyle}
              >
                {isStudioTab
                  ? renderStudioItems(row)
                  : renderFriendsItems(row, index)}
              </TableRow>
            );
          })}
          {isUserOutsideOfTopTwenty && (
            <OutsideTableCell
              style={lastTableRowStyle}
              currentUser={currentParticipant}
              renderItems={renderStudioItems}
            />
          )}
        </TableBody>
      </Table>
    );
  };

  if (isIframe) {
    return renderTable();
  }

  return (
    <TableContainer component={Paper} className="basic-table-container">
      {renderTable()}
    </TableContainer>
  );
};

export default BasicTable;
