import React from "react";
import { DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";

import "./dialog.scss";

type Props = {
  title: string;
  onClose(): void;
};

export function EnhancedDialogTitle({ title, onClose }: Props) {
  return (
    <DialogTitle>
      <div className="dialog-title-container">
        {title}
        <div className="close-icon">
          <Close onClick={onClose} />
        </div>
      </div>
    </DialogTitle>
  );
}
