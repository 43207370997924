import React from "react";
import { TableCell } from "@mui/material";
import cx from "classnames";

import { Avatar } from "../Avatar";
import { ClientNameCell } from "../ClientNameCell";
import { NavigationTabTypes } from "../../../../constants/navigation";
import { getClientName } from "../../../../utils/text";

import "./paticipants.scss";

type ParticipantsProps = {
  type: string;
  row: any;
  isCompleted?: boolean;
  index?: number;
  width?: number;
  currentUser?: any;
};

export function Participants({
  type,
  row,
  isCompleted,
  index = 0,
  width,
  currentUser,
}: ParticipantsProps) {
  const isStudio = type === NavigationTabTypes.Studio;

  const cellClass = cx({
    "participants-cell": true,
    highlighted: isCompleted,
  });

  const isCurrentUserExist = (): boolean => {
    if (isStudio) {
      return currentUser?.rank === row?.rank;
    }

    return false;
  };

  const isCurrentUser = isCurrentUserExist();
  const clientName = getClientName(row.firstName, row.lastName);
  return (
    <TableCell width={width} size="small" align="left" className={cellClass}>
      <div className="participants-cell--inner">
        <div className="rank-container">{isStudio ? row.rank : index + 1}</div>
        <Avatar
          type={type}
          row={row}
          isCompleted={isCompleted}
          isCurrentUser={isCurrentUser}
        />
        <ClientNameCell
          type={type}
          row={row}
          clientName={clientName}
          isMobile
        />
      </div>
    </TableCell>
  );
}
