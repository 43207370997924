import { NavigationTabTypes } from "../constants/navigation";

export const MixpanelEventTypes = {
  Click: 'click',
  View: 'view',
}

export const MixpanelEvents = {
  [NavigationTabTypes.Studio]: {
    Avatar: {
      name: "Studio leaderboard > avatar",
      type: MixpanelEventTypes.Click
    },
    MoreDetails: {
      name: "Studio leaderboard > more details",
      type: MixpanelEventTypes.Click
    },
    UserName: {
      name: "Studio leaderboard > username",
      type: MixpanelEventTypes.Click
    },
    TabChange: {
      name: "Studio leaderboard > tab selected",
      type: MixpanelEventTypes.Click
    },
    ActiveFN: {
      name: 'Studio leaderboard > select studio',
      type: MixpanelEventTypes.Click,
    },
    OnTab: {
      name: 'Studio leaderboard',
      type: MixpanelEventTypes.View
    },
    TermsLink: {
      name: 'Studio leaderboard > terms and conditions',
      type: MixpanelEventTypes.Click
    },
    LetUsKnow: {
      name: 'Studio leaderboard > let us know',
      type: MixpanelEventTypes.Click
    },
    Page404: {
      name: 'Studio leaderboard > 404',
      type: MixpanelEventTypes.View
    }
  },
  [NavigationTabTypes.Friends]: {
    Avatar: {
      name: "Friends leaderboard > avatar",
      type: MixpanelEventTypes.Click
    },
    MoreDetails: {
      name: "Friends leaderboard > more details",
      type: MixpanelEventTypes.Click
    },
    UserName: {
      name: "Friends leaderboard > username",
      type: MixpanelEventTypes.Click
    },
    TabChange: {
      name: "Friends leaderboard > tab selected",
      type: MixpanelEventTypes.Click
    },
    OnTab: {
      name: 'Friends leaderboard',
      type: MixpanelEventTypes.View
    },
    TermsLink: {
      name: 'Friends leaderboard > terms and conditions',
      type: MixpanelEventTypes.Click
    },
    LetUsKnow: {
      name: 'Friends leaderboard > let us know',
      type: MixpanelEventTypes.Click
    },
    Page404: {
      name: 'Friends leaderboard > 404',
      type: MixpanelEventTypes.View
    }
  },
}
