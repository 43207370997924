type StorageReturnType = string | null | undefined;

export const setValueToStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log("error on settings value to local storage", e);
  }
};

export const getValueFromStorage = (key: string): StorageReturnType => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log("error on settings value to local storage", e);
  }
};
