import React from "react";
import NavigationTab from "./NavigationTab";

import "./navigation-tabs.scss";

type TabProps = {
  name: string;
  id: string;
};

type NavigationTabsProps = {
  tabs: TabProps[];
  onSetActiveTab(tabId: string): void;
  activeTabId: string;
};

const NavigationTabs: React.FC<NavigationTabsProps> = ({
  tabs,
  onSetActiveTab,
  activeTabId,
}) => {
  return (
    <div className="navigation">
      {tabs.map(({ name, id }: TabProps) => (
        <NavigationTab
          key={id}
          onSetActiveTab={onSetActiveTab}
          navigationTabId={id}
          navigationTabName={name}
          isNavigationTabActive={activeTabId === id}
        />
      ))}
    </div>
  );
};

export default NavigationTabs;
