import moment from "moment-timezone";
import { QueryVariables } from "../types/api";
import { ALLOWED_PARTICIPANTS_AMOUNT } from "../constants/table";

export function getParameterById(id: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(id);
}

export function getBooleanParameterFromQuery(id: string) {
  return Boolean(getParameterById(id)) || false;
}

export function formatToDateString(date: number) {
  return moment.unix(date).tz("America/New_York").format("MMMM D, yyyy")
}

export function formatToShortDateString(date: number) {
  return moment.unix(date).tz("America/New_York").format("MMM DD");
}

export const getQueryParameters = (): QueryVariables => {
  const sgId = getParameterById("sgid");
  const challengeId = getParameterById("chid");

  return { sgId, challengeId };
};

export const getParticipantsNodes = (
  data: any,
  isStudioTab: boolean,
  currentUser: any
): { participants: any; isUserOutsideOfTopTwenty?: boolean } => {
  const nodes = isStudioTab ? data?.participants?.nodes : data?.rows?.nodes;
  let isUserOutsideOfTopTwenty;

  const participants = nodes.slice(0, ALLOWED_PARTICIPANTS_AMOUNT);

  if (currentUser) {
    isUserOutsideOfTopTwenty = currentUser?.rank > 20;
  }

  return {
    participants,
    isUserOutsideOfTopTwenty,
  };
};

export const ScrollingDirection = {
  Down: "down",
  Up: "up",
};

export const ScrollingPosition = {
  Start: "start",
  Third: "third",
  Half: "half",
  Bottom: "bottom",
};

// scrolling helpers
function checkScrollDirectionIsUp(event: any) {
  if (event.wheelDelta) {
    return event.wheelDelta > 0;
  }
  return event.deltaY < 0;
}

export function checkScrollDirection(event: any) {
  if (checkScrollDirectionIsUp(event)) {
    return ScrollingDirection.Up;
  } else {
    return ScrollingDirection.Down;
  }
}

export function getUserScrollPosition(event: any) {
  let position;

  const direction = checkScrollDirection(event);

  if (direction === ScrollingDirection.Up) {
    position = null;
  }

  const wholePageHeight = document.body.offsetHeight;
  const thirdPageHeight = wholePageHeight / 3;
  const halfPageHeight = wholePageHeight / 2;

  const currentHeight = window.innerHeight + window.scrollY;

  if (currentHeight >= thirdPageHeight) {
    position = ScrollingPosition.Third;
  }

  if (currentHeight >= halfPageHeight) {
    position = ScrollingPosition.Half;
  }

  if (currentHeight >= wholePageHeight) {
    // you're at the bottom of the page
    position = ScrollingPosition.Bottom;
  }

  return position;
}
