import React from "react";
import { ReactComponent as CalendarSvg } from "../../../icons/calendar_today.svg";
import { ReactComponent as CardSvg } from "../../../icons/card_giftcard.svg";
import {
  formatToDateString,
  formatToShortDateString,
} from "../../../utils/misc";

import "./studio-tab-content.scss";

type Props = {
  onClick(): void;
  selectedNetwork: any;
  isSA?: boolean;
};

export function StudioTabTopContent({ selectedNetwork, onClick, isSA }: Props) {
  const endTimePath = isSA ? 'endTime' : 'challengeEndTime';
  const startTimePath = isSA ? 'startTime' : 'challengeStartTime';

  const getChallengeTimeRange = () => {
    const endTime = formatToDateString(selectedNetwork[endTimePath]);
    const startTime = formatToDateString(selectedNetwork[startTimePath]);

    return `${startTime} - 11:59PM ET ${endTime}`;
  };

  const getShortTimeRange = () => {
    const endTime = formatToShortDateString(selectedNetwork[endTimePath]);
    const startTime = formatToShortDateString(selectedNetwork[startTimePath]);

    return `${startTime} - ${endTime}`;
  };

  const timeRange = getChallengeTimeRange();
  const shortTimeRange = getShortTimeRange();

  const text = '$300 Nike Gift Card'

  return (
    <div className="studio-top-content">
      <div className="section">
        <CalendarSvg className="icon" />
        <span className="text">{timeRange}</span>
      </div>
      <div className="section">
        <CardSvg className="icon" />
        <span className="text">{text}</span>
      </div>

      <div className="description">
        Reserve 4 classes via the FitGrid Class App, attend between {shortTimeRange}, and be entered into a drawing for the prize.
        <br />
        <span className="more-details-link" onClick={onClick}>
          More details
        </span>
      </div>
    </div>
  );
}
